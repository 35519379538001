<template>
  <svg
    fill="none"
    height="104"
    viewBox="0 0 108 104"
    width="108"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M49.8813 97.9362L13.349 102.064C6.6792 102.55 1 97.2698 1 90.582V12.6025L49.8813 14.7645V97.9362Z"
      fill="currentColor"
    />
    <path
      :fill="
        menuStore.CurrentGroup?.UseAlternateTheme
          ? appConfig.VueSettingsPreRun.Theme.AlternativeTheme.BodyBackground
          : appConfig.VueSettingsPreRun.Theme.DefaultTheme.BodyBackground
      "
      d="M107.207 86.111C107.207 90.3274 104.033 94.0292 100.077 94.3825L13.6852 102.095C18.6689 101.65 22.6634 97.4604 22.6634 92.7315V1.2002L107.207 4.48953V86.111Z"
    />
    <path
      d="M100.922 36.2476L69.5171 36.456V33.281L100.922 33.2085V36.2476Z"
      fill="currentColor"
    />
    <path
      d="M66.0955 63.3988L31.4235 64.8944V33.369L66.0955 33.2891V63.3988Z"
      fill="currentColor"
    />
    <path
      d="M100.757 29.257L31.5 29.5V17.2837L100.757 17.5V29.257Z"
      fill="currentColor"
    />
    <path
      d="M100.922 41.377L69.5171 41.8147V38.6405L100.922 38.3379V41.377Z"
      fill="currentColor"
    />
    <path
      d="M100.922 46.5073L69.5171 47.1745V43.9994L100.922 43.4683V46.5073Z"
      fill="currentColor"
    />
    <path
      d="M100.922 51.6362L69.5171 52.5327V49.3577L100.922 48.5972V51.6362Z"
      fill="currentColor"
    />
    <path
      d="M100.922 56.7666L69.5171 57.8924V54.7174L100.922 53.7275V56.7666Z"
      fill="currentColor"
    />
    <path
      d="M100.922 61.896L69.5171 63.2512V60.0761L100.922 58.8569V61.896Z"
      fill="currentColor"
    />
    <path
      d="M100.922 68.8472L69.5171 70.5131V67.3381L100.922 65.8081V68.8472Z"
      fill="currentColor"
    />
    <path
      d="M100.922 73.9775L69.5171 75.8721V72.6978L100.922 70.9385V73.9775Z"
      fill="currentColor"
    />
    <path
      d="M100.922 79.1069L69.5171 81.2316V78.0566L100.922 76.0679V79.1069Z"
      fill="currentColor"
    />
    <path
      d="M100.922 84.2368L69.5171 86.5901V83.4158L100.922 81.1978V84.2368Z"
      fill="currentColor"
    />
    <path
      d="M100.922 89.3662L69.5172 91.9496V88.7745L100.922 86.3271V89.3662Z"
      fill="currentColor"
    />
    <path
      d="M66.0955 70.6949L31.4235 72.5341V69.194L66.0955 67.5049V70.6949Z"
      fill="currentColor"
    />
    <path
      d="M66.0955 76.0786L31.4235 78.1704V74.831L66.0955 72.8887V76.0786Z"
      fill="currentColor"
    />
    <path
      d="M66.0955 81.4629L31.4235 83.808V80.4686L66.0955 78.2729V81.4629Z"
      fill="currentColor"
    />
    <path
      d="M66.0955 86.8465L31.4235 89.4455V86.1054L66.0955 83.6572V86.8465Z"
      fill="currentColor"
    />
    <path
      d="M66.0955 92.2302L31.4235 95.0818V91.7424L66.0955 89.041V92.2302Z"
      fill="currentColor"
    />
  </svg>
</template>

<script setup lang="ts">
const appConfig = useAppConfig()
const menuStore = useMenuStore()
</script>
